body{margin:0;padding:0;}
.centralize{
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    margin-top: 4rem;
    font-weight: 200;
}
.centralize h1{
    width: auto;
    text-align: center;
    padding:8px;
    
    border: 1px solid ;
    font-weight: 100!important;
}
footer{

    font-size: 0.8rem;
}
.p-l-8{
    padding-left:8px ;
}
.b-0{
    position:absolute;
    bottom: 0;
}