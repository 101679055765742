body {
  margin: 0;
  padding: 0;
}

.centralize {
  width: 200px;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  font-weight: 200;
}

.centralize h1 {
  width: auto;
  text-align: center;
  border: 1px solid;
  padding: 8px;
  font-weight: 100 !important;
}

footer {
  font-size: .8rem;
}

.p-l-8 {
  padding-left: 8px;
}

.b-0 {
  position: absolute;
  bottom: 0;
}

/*# sourceMappingURL=index.d4dc2ba4.css.map */
